import React from 'react'
import axios from 'axios'
import useSWR from 'swr'

const SpotifyComp = ({ url }) => {

    const fetcher = url => axios.get(url).then(res => res.data)
    const spotifyBaseUrl = 'https://open.spotify.com/oembed?url='

    const { data } = useSWR(`${spotifyBaseUrl}${url}`, fetcher)
    return data ? (
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
    ) : null
}

export default SpotifyComp