import React from 'react'
import useSWR from 'swr'
import axios from 'axios'

const SoundCloudComp = ({ url }) => {

    const fetcher = url => axios.get(url).then(res => res.data)
    const soundCloudBaseUrl = 'https://soundcloud.com/oembed?format=json&url='

    const { data } = useSWR(`${soundCloudBaseUrl}${url}`, fetcher)
    return data ? (
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
    ) : null
}

export default SoundCloudComp