import React, { useEffect, useRef } from 'react'
import { getDictionary } from '@/src/dictionary'

const JWMediaURL = 'https://cdn.jwplayer.com/v2/'
const videoDivId = 'jwppp-video'

const VideoPlayer = ({ jwPlayerId }) => {
    let cleanJwPlayerId = null

    if (jwPlayerId) {
        cleanJwPlayerId = jwPlayerId.trim()
        if (!cleanJwPlayerId.includes(`media/`) && !cleanJwPlayerId.includes(`playlists/`)) {
            cleanJwPlayerId = `media/${cleanJwPlayerId}`
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (cleanJwPlayerId) {
                window.jwplayer(videoDivId).setup({
                    playlist: JWMediaURL + cleanJwPlayerId,
                    autostart: 'viewable',
                    mute: true,
                    advertising: {
                        client: 'googima',
                        tag: getDictionary('adsTag'),
                    },
                    floating: {
                        mode: 'never',
                    },
                })
            } else {
                window.jwplayer(videoDivId).remove()
            }
        }
    }, [cleanJwPlayerId])

    return (
        <div id={videoDivId} key={videoDivId}
            className='jwppp-video-box'
            style={{ margin: '1rem 0' }}>
        </div>
    )
}

export default VideoPlayer
