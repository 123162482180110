import React from 'react'

function AppleMusicComp({ url }) {
    return (
        <div className='left-0, w-[100%] relative'>
            <iframe allow='autoplay *; encrypted-media *; fullscreen *; clipboard-write'
                    className='w-[100%] overflow-hidden rounded-[10px]'
                    sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
                    src={url}>
            </iframe>
        </div>
    )
}

export default AppleMusicComp