import ScriptComp from '@/components/ScriptComp'
import VideoPlayer from '@/components/VideoPlayer'
import AppleMusicComp from '@/components/embedded/AppleMusicComp'
import GoogleFormComp from '@/components/embedded/GoogleFormComp'
import MixCloudComp from '@/components/embedded/MixCloudComp'
import OmnyFMComp from '@/components/embedded/OmnyFMComp'
import SoundCloudComp from '@/components/embedded/SoundCloudComp'
import SpotifyComp from '@/components/embedded/SpotifyComp'
import { getDictionary, getImagePath, isHot97 } from '@/src/dictionary'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Image from 'next/image'
import Link from 'next/link'
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, TwitterEmbed, YouTubeEmbed } from 'react-social-media-embed'
import { Fragment } from 'react'

const lineBreaks = /\r?\n|\r|\n/g
const linkRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/
const linkInBetweenRegex = /(<p>{1}).+((<a.*?>)(.*?)(<\/a>)).+(<\/p>{1})/g

// extracts a string excerpt by limiting the number of characters defined in the limit parameter
export function getExcerpt(string, limit) {
    if (!string) return ''
    let newString = string.replace(/(<([^>]+)>)/gi, '').replace(/&+\w*;/gim, '')
    if (newString.trim().length >= limit) {
        return `${newString.slice(0, limit)} ...`
    }
    return newString
}

export function buildCard(item, titleLimit, descLimit) {

    const imgURL = (item?.images !== undefined && item?.images[0] !== undefined)
        ? item.images[0]
        : getImagePath('website-logo.png')

    return (
        (item !== null && item !== undefined) ?
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'black',
                boxSizing: 'border-box',
                border: 'thin solid #333',
            }}>
                <div style={{
                    position: 'relative',
                    paddingBottom: 'max(56.2500%)',
                }}>
                    <Link href={item?.url}>
                        <Image
                            src={imgURL}
                            alt={item.title}
                            style={{
                                objectFit: 'contain',
                                filter: 'brightness(1)',
                            }}
                            fill={true}
                        />
                    </Link>
                </div>
                <div style={{ padding: '8px 8px', boxSizing: 'border-box' }}>
                    <div style={{
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
                        backgroundColor: '#000',
                        lineHeight: '18px',
                    }}>
                        <div style={{
                            fontWeight: '700',
                            fontSize: '13px',
                            marginBottom: '.5em',
                        }}>
                            <Link href={item?.url} style={{ textDecoration: 'none', color: 'white' }}>
                                {getExcerpt(item?.title, titleLimit)}
                            </Link>
                        </div>
                        <div style={{
                            marginBottom: '.6em',
                            lineHeight: '17px',
                            color: 'white',
                        }}>
                            {getExcerpt(item?.description, descLimit)}
                        </div>
                        <div style={{
                            color: '#808080',
                            display: 'flex',
                        }}>
                            <Link href={item?.url} style={{ textDecoration: 'none', color: '#808080' }}>
                                <Image src={getImagePath('dist-images-logo.svg')}
                                       title={getDictionary(`indexTitle`)}
                                       alt={getDictionary(`indexTitle`)}
                                       blurDataURL={getImagePath('dist-images-logo.svg')}
                                       placeholder='blur'
                                       width={16}
                                       height={16}
                                       priority={true}
                                       style={{ marginRight: '6px' }}
                                />
                                {getDictionary('websiteName')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            : null
    )
}

// some paragraphs in Contentful rich text editor contains different kinds of linebreaks
// this function splits the content (if it finds any) and then searches for social links
// then returns the corresponding embedded elements
function handleBreakLines(text) {

    if (text !== undefined && !text.hasOwnProperty('props')) {
        if (text.match(lineBreaks)) {

            const content = text.split(lineBreaks)

            if (content.find(isScript) !== undefined) {
                return handleScripts(content)
            }

            if (content.length > 0) {
                return content.map(slice => {
                    const socialElement = handleSocials(slice)
                    if (socialElement !== null) {
                        return socialElement
                    }
                    return <>{slice}<br /></>
                })
            }
        } else {
            const socialElement = handleSocials(text)
            if (socialElement !== null) {
                return socialElement
            }
        }
    }

    // verifies if content has line breaks or several lines of text so they can be replaced by <br/>
    if (text !== undefined && text.hasOwnProperty('props')) {
        if (!Array.isArray(text.props.children)) {
            if (text.props.hasOwnProperty('children')) {
                if (typeof text.props.children === 'string' && text.props.children?.match(lineBreaks)) {
                    return <>{text}<br /></>
                }
            }
        }
    }

    return text
}

export function convertSocials(htmlContent) {
    let responseDivElements = []
    const wpEmbeds = htmlContent.match(/<!-- wp:embed[\s\S]*?<!-- \/wp:embed -->/gm)
    const paragraphs = htmlContent.match(/(<p.*?>)(.*?)(<\/p>)/gm)
    if (wpEmbeds) {
        wpEmbeds.forEach(wpEmbed => {
            const url = wpEmbed.match(/"http\S*?"/)
            if (url) {
                let cleanUrl = url[0].replaceAll('"', '')
                while (cleanUrl.endsWith('\\')) cleanUrl = cleanUrl.slice(0, -1)
                const social = handleSocials(cleanUrl)
                if (social) {
                    const socialStartsAt = htmlContent.indexOf(wpEmbed)
                    const beforeSocialPart = htmlContent.slice(0, socialStartsAt)
                    responseDivElements.push(<div key={Math.random()}
                                                  dangerouslySetInnerHTML={{ __html: beforeSocialPart }} />)
                    responseDivElements.push(social)
                    const afterSocialPartStartsAt = socialStartsAt + wpEmbed.length
                    htmlContent = htmlContent.slice(afterSocialPartStartsAt)
                }
            }
        })
        if (htmlContent.trim().length > 0) {
            responseDivElements.push(<div key={Math.random()} dangerouslySetInnerHTML={{ __html: htmlContent }} />)
        }
    } else if (paragraphs) {
        paragraphs.forEach(paragraph => {
            const url = paragraph.match(linkRegex)
            if (url) {
                const isLinkInBetween = paragraph.match(linkInBetweenRegex)
                if (!isLinkInBetween) {
                    let cleanUrl = url[0].replaceAll(/<.>/g, '').replaceAll('/<\/.>/g', '').replaceAll('&nbsp', '').trim()
                    while (cleanUrl.endsWith('\\')) cleanUrl = cleanUrl.slice(0, -1)
                    const social = handleSocials(cleanUrl)
                    if (social) {
                        const socialStartsAt = htmlContent.indexOf(paragraph)
                        const beforeSocialPart = htmlContent.slice(0, socialStartsAt)
                        responseDivElements.push(<div key={Math.random()}
                                                      dangerouslySetInnerHTML={{ __html: beforeSocialPart }} />)
                        responseDivElements.push(social)
                        const afterSocialPartStartsAt = socialStartsAt + paragraph.length
                        htmlContent = htmlContent.slice(afterSocialPartStartsAt)
                    }
                }
            }
        })
        if (htmlContent.trim().length > 0) {
            responseDivElements.push(<div key={Math.random()} dangerouslySetInnerHTML={{ __html: htmlContent }} />)
        }

    } else {
        responseDivElements.push(<div key={Math.random()} dangerouslySetInnerHTML={{ __html: htmlContent }} />)
    }
    return (
        <Fragment key={Math.random()}>
            {responseDivElements}
        </Fragment>
    )
}

function handleSocials(url) {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
        return <YouTubeEmbed key={Math.random()} url={url} />
    } else if (url.includes('instagram.com')) {
        if (url.includes('ig_web_copy_link')) {
            url = url.replace('ig_web_copy_link', 'ig_embed').split('&igshid=')[0]
        }
        return <InstagramEmbed key={Math.random()} url={url} />
    } else if (url.includes('twitter.com') || url.includes('http://x.com') || url.includes('https://x.com')) {
        return (
            <TwitterEmbed key={Math.random()} url={url} />
        )
    } else if (url.includes('tiktok.com')) {
        return <TikTokEmbed key={Math.random()} url={url} />
    } else if (url.includes('apple.com')) {
        const correctApple = 'https://embed.music.apple.com'
        url = url.replace('https://itunes.apple.com', correctApple)
        url = url.replace('https://music.apple.com', correctApple)
        url = url.replace('https://embed.itunes.apple.com', correctApple)
        return <AppleMusicComp key={Math.random()} url={url} />
    } else if (url.includes('spotify.com')) {
        return <SpotifyComp key={Math.random()} url={url} />
    } else if (url.includes('facebook.com')) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FacebookEmbed key={Math.random()} url={url} width={500} />
            </div>
        )
    } else if (url.includes('soundcloud.com')) {
        return <SoundCloudComp key={Math.random()} url={url} />
    } else if (url.includes('omny.fm')) {
        return <OmnyFMComp key={Math.random()} url={url} />
    } else if (url.includes('mixcloud.com')) {
        return <MixCloudComp key={Math.random()} url={url} />
    } else if (url.includes('/top50')) {
        return (
            <div className='wp-container-64d6840dda91f wp-block-buttons'>
                <div
                    className='wp-block-button has-custom-width wp-block-button__width-100 has-custom-font-size is-style-primary has-lede-font-size'>
                    <Link
                        className='wp-block-button__link has-hot-yellow-100-color has-dark-gray-background-color has-text-color has-background'
                        href={new URL(url).pathname}
                        style={{ borderRadius: '50px' }}
                        target='_blank'
                        rel='noreferrer noopener'>See The Whole List!
                    </Link>
                </div>
            </div>
        )
    } else if (url.includes('/subscribe')) {
        return (
            <div className='wp-block-buttons' style={{
                display: 'flex',
                gap: '0.5em',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <div className='wp-block-button is-style-primary'>
                    <a href={url} className='wp-block-button__link'>SUBSCRIBE TODAY</a>
                </div>
            </div>
        )
    } else if (url.includes('hot97app')) {
        return (
            <div className='wp-container-64dd4b52ae330 wp-block-buttons'>
                <div className='wp-block-button clear-both block mx-auto text-center is-style-primary'><a
                    className='wp-block-button__link' href='http://onelink.to/hot97app'
                    target='_blank' rel='noreferrer noopener'>Download The HOT 97 App</a></div>
            </div>
        )
    } else if (url.includes('cdn.jwplayer.com')) {
        const videoURL = new URL(url)
        return <VideoPlayer key={Math.random()} jwPlayerId={videoURL.pathname.replace('/previews/', '')} />
    } else if (url.includes('ticketmaster.com')) {
        return (
            <div className='wp-container-6500e156dad37 wp-block-buttons'>
                <div
                    className='wp-block-button has-custom-width wp-block-button__width-100 has-custom-font-size is-style-primary has-lede-font-size'>
                    <a className='wp-block-button__link has-dark-gray-color has-hot-yellow-200-background-color has-text-color has-background'
                       href={url}
                       target='_blank' rel='noreferrer noopener'>Tickets available now at Ticketmaster!</a>
                </div>
            </div>
        )
    } else if (url.includes('forms.gle') || url.includes('docs.google.com')) {
        return <GoogleFormComp key={Math.random()} url={url} />
    }

    return null
}

export function getRenderOptions() {
    return {
        renderNode: {
            // paragraphs contains different kinds of elements (links, special characters, etc.)
            // that are treated as objects, this implementation loops through each nested item
            // and looks for social links
            [BLOCKS.PARAGRAPH]: (node, children) => {

                let result = []
                let nestedItem = []

                result = children.map((item, index) => {

                    const socials = handleBreakLines(item)

                    nestedItem.push(socials)

                    if (children.length === (index + 1)) {
                        if (nestedItem.length === (index + 1)) {
                            const returnNested = nestedItem
                            nestedItem = []
                            return <div key={Math.random()}>{returnNested}</div>
                        }

                        return <p key={Math.random()}>{item}</p>
                    }
                })
                return result
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                const width = node.data.target.fields.file.details.image?.width !== undefined ? node.data.target.fields.file.details.image.width : '683'
                const height = node.data.target.fields.file.details.image?.height !== undefined ? node.data.target.fields.file.details.image.height : '1024'
                return (
                    <>
                        <figure className='' style={{ objectFit: 'contain' }}>
                            <Image src={'https:' + node.data.target.fields.file.url}
                                   alt={node.data.target.fields.title}
                                   width={width}
                                   height={height}
                            />
                        </figure>
                        {
                            (node.data.target.fields.description !== undefined && node.data.target.fields.description !== '')
                                ? <figcaption>{node.data.target.fields.description}</figcaption>
                                : null
                        }
                    </>
                )
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                if (node.data.target.sys.contentType) {
                    const contentType = node.data.target.sys.contentType.sys.id
                    const card = {}

                    if (contentType === 'post') {
                        card.url = `${getDictionary('postsPath')}${node.data.target.fields.slug}`
                    } else if (contentType === 'pages') {
                        card.url = `/${node.data.target.fields.url}`
                    }

                    card.title = node.data.target.fields.title

                    if (node.data.target.fields.content.content[0].content[0].value !== undefined) {
                        card.description = node.data.target.fields.content.content[0].content[0].value
                    } else {
                        card.description = node.data.target.fields.content.content[0].content[0].content[0].value
                    }

                    if (node.data.target.fields.hasOwnProperty('banner')) {
                        card.images = [`https:${node.data.target.fields.banner?.fields.file.url}`]
                    }

                    return buildCard(card, 100, 100)
                }
            },
            [BLOCKS.TABLE]: (node, children) => {
                return <div key={Math.random()}>{children}</div>
            },
            [BLOCKS.TABLE_ROW]: (node, children) => {
                return (
                    <div key={Math.random()}
                         className={isHot97() ? 'wp-block-columns' : 'is-layout-flex wp-block-columns'}
                         style={{ textAlign: 'left', paddingLeft: '30px', paddingTop: '20px' }}>{children}</div>
                )
            },
            [BLOCKS.TABLE_CELL]: (node, children) => {
                return (
                    <div key={Math.random()}
                         className={isHot97() ? 'wp-block-column' : 'is-layout-flex wp-block-column'}
                         style={{ verticalAlign: 'middle' }}>{children}</div>
                )
            },
            [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
                return children
            },
            [BLOCKS.OL_LIST]: (node, children) => {

                let listElements = []

                children.map(item => {
                    listElements.push(
                        <div key={Math.random()}
                             className={isHot97() ? 'wp-block-column' : 'is-layout-flex wp-block-column'}>
                            {item.props.children}
                        </div>,
                    )
                })

                return (
                    <div key={Math.random()}
                         className={isHot97() ? 'wp-block-columns' : 'is-layout-flex wp-block-columns'}>
                        {listElements}
                    </div>
                )
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul key={Math.random()} style={{ paddingLeft: '0px' }}>{children}</ul>
                )
            },
            [BLOCKS.HR]: (node) => {
                return <hr className='wp-block-separator' style={{ marginTop: '3em' }} />
            },
            [BLOCKS.HEADING_1]: (node) => {
                return <h1 className={'clear-both block mx-auto text-center'}>{node.content[0].value}</h1>
            },
            [BLOCKS.HEADING_2]: (node) => {
                return <h2 className={'clear-both block mx-auto text-center'}>{node.content[0].value}</h2>
            },
            [BLOCKS.HEADING_3]: (node) => {
                return <h3 className={'clear-both block mx-auto text-center'}>{node.content[0].value}</h3>
            },
            [BLOCKS.HEADING_4]: (node) => {
                return <h4 className={'clear-both block mx-auto text-center'}
                           style={{ paddingBottom: '25px' }}>{node.content[0].value}</h4>
            },
        },
    }
}

function isScript(string) {
    return string.includes('<script')
}

function handleScripts(scriptString) {
    return <ScriptComp scriptString={scriptString} />
}
