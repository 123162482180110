import React from 'react'

function MixCloudComp({ url }) {
    return (
        <figure className='wp-block-embed is-type-rich is-provider-mixcloud wp-block-embed-mixcloud'>
            <div className='wp-block-embed__wrapper'>
                <div style={{ left: 0, width: '100%', height: '60px', position: 'relative' }}>
                    <iframe loading='lazy'
                            src={'https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=' + url}
                            style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', border: 0 }}
                            allowFullScreen='' scrolling='no' data-rocket-lazyload='fitvidscompatible'
                            className='lazyloaded' data-ll-status='loaded' />
                </div>
            </div>
        </figure>
    )
}

export default MixCloudComp