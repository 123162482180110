import React from 'react'

function OmnyFMComp({ url }) {
    return (
        <div className='wp-block-group__inner-container'>
            <iframe loading='lazy'
                    src={url + '/embed?style=cover'}
                    allow='autoplay; clipboard-write'
                    width='100%'
                    height='500'
                    frameBorder='0'
                    data-rocket-lazyload='fitvidscompatible'
                    className='lazyloaded'
                    data-ll-status='loaded' />
        </div>
    )
}

export default OmnyFMComp