import React, { useEffect } from 'react'
import Script from 'next/script'

const ScriptComp = ({ scriptString }) => {

    let scripts = []
    let scriptStr = ''
    let isScript = false

    // loop through the array of scrip strings received, if there's any break lines we skip them and
    // concatenate every piece to build the full script as a single line
    scriptString.map(slice => {
        if (slice.includes('<script') && slice.includes('</script')) {
            scripts.push(slice)
        } else {

            if (slice.includes('</script')) {
                scriptStr += slice
                scripts.push(scriptStr)
                scriptStr = ''
                isScript = false
            }

            if (slice.includes('<script')) {
                scriptStr += slice
                isScript = true
            } else if (isScript) {
                scriptStr += slice
            }
        }
    })

    const scriptTags = []

    // if there's any script that has a source (src) we build a <Script> to load them first, before render and hydration
    scripts.map(scriptItem => {
        if (scriptItem.includes('src')) {
            let src = scriptItem.match(/(?<=src=").*(?=")/g)
            if (src.length > 0) {
                scriptTags.push(<Script key={Math.random()} src={src} strategy={'beforeInteractive'} />)
            }
        }
    })

    useEffect(() => {
        // we load the rest of the scripts once the page has rendered and loaded
        scripts.map(scriptItem => {
            if (!scriptItem.includes('src')) {
                const scriptElement = document.createElement('script')
                scriptElement.innerHTML = scriptItem.replaceAll(/<script>| |<\/script>/g, '')
                document.getElementById('embedded-scripts').appendChild(scriptElement)
            }
        })
    })

    return (
        <>
            {scriptTags}
            <div id='embedded-scripts'></div>
        </>
    )
}

export default ScriptComp