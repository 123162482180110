import React from 'react'

const GoogleFormComp = ({ url }) => {
    return (
        <div>
            <iframe id='embedded-google-form'
                    src={url} className='border-none h-[100vmax] w-[100vmax]' />
        </div>
    )
}

export default GoogleFormComp